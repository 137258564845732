import { useEffect, useState } from "react";
import MiniFooter from "../LandingV2/MiniFooter";
import Navbar from "../LandingV2/Navbar";
import SubNavbar from "../LandingV2/SubNavbar";
import FilterSelect from "./FilterSelect";
import "./Home.scss";

import GraphDataDummy from "../StockSelection/Apr-2014.json";
import PlotDataDummy from "../../data/stock-selection/plots_data.json";
import HistoricalDataDummy from "../../data/stock-selection/table_data.json";
import NormalizedLineGraph from "./NormalizedLineGraph";
import BacktestingTable from "./BacktestingTable";
import BacktestingGraph from "./BacktestingGraph";
import HeatMap from "./HeatMap";
import Plot from "react-plotly.js";

// Define the GraphData type
export type PerformanceData = {
  Date: string[];
  Investment: number[];
};

export type StockParameters = {
  "Initial Capital": number;
  "Final Capital": number;
  "Annualized Returns": number;
  "Calmar Ratio": number;
  "Sharpe Ratio": number;
  "Sortino Ratio": number;
  "Max Drawdown": number;
  [key: string]: number;
};

export type NormalizedPrices = {
  Date: string[];
  [key: string]: number[] | string[];
};

export type FilterData = {
  Stocks: string[];
  "Backtesting Performance Our Stock": PerformanceData;
  "Backtesting Performance NIFTYBEES": PerformanceData;
  "Our Stocks Parameters"?: StockParameters;
  "NIFTYBEES Parameters"?: StockParameters;
  "Normalized Price Over Time STOCKS": NormalizedPrices;
  "Normalized Price Over Time INDICES": NormalizedPrices;
};

export type GraphData = {
  "Filter 1 Stocks": FilterData;
  "Filter 2 Stocks": FilterData;
  "Filter 3 Stocks": FilterData;
};

type PlotData = {
  "Filter 1 Stocks": Filters;
  NIFTYBEES: NIFTYBEES;
  "Universe 0 (TMI)": Universe;
  "Filter 2 Stocks": Filters;
  "Filter 3 Stocks Max Sharpe 1Y": Filters;
};

type HistoricalData = {
  "Filter 1 Stocks": StockParameters;
  "NIFTYBEES.NS": StockParameters;
  "NIFTY TOTAL MARKET": StockParameters;
  "Filter 2 Stocks": StockParameters;
  "Filter 3 Stocks Max Sharpe 1Y": StockParameters;
  [key: string]: StockParameters;
};

type Filters = {
  "Final Investment": number[];
  Date: string[];
};

export interface NIFTYBEES {
  "NIFTYBEES.NS"?: number[];
  "Final Investment": number[];
  Date: string[];
}
export interface Universe {
  " NIFTY TOTAL MARKET"?: number[];
  "Final Investment": number[];
  Date: string[];
}
const orderedKeys = [
  "NIFTYBEES.NS",
  "NIFTY TOTAL MARKET",
  "Filter 1 Stocks",
  "Filter 2 Stocks",
  "Filter 3 Stocks Max Sharpe 1Y",
];

const colors = ["#5186AD", "#EC7F18", "#00B050", "#FF0000", "#74588D"];

const subNavData = [
  {
    name: "Strong Stocks",
    href: "/strong-stocks-home",
    dropdown: [
      {
        name: "Home",
        href: "/strong-stocks-home",
      },
      {
        name: "Stock Selection",
        href: "/stock-selection",
      },
    ],
  },
  {
    name: "Nifty Statergy",
    href: "/nifty-statergy",
  },
  {
    name: "Stock C2C",
    href: "/stock-c2c",
  },
];

const getFutureMonths = (currentYear: number, selectedYear: string) => {
  const currentMonth = new Date().getMonth(); // 0 (Jan) to 11 (Dec)
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (parseInt(selectedYear) === currentYear) {
    return months.slice(currentMonth);
  }
  return [];
};
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const years = [
  "2024",
  "2023",
  "2022",
  "2021",
  "2020",
  "2019",
  "2018",
  "2017",
  "2016",
  "2015",
  "2014",
];

// const aggregateByMonth = (data: Filters) => {
//   const aggregatedData = {} as {
//     [key: string]: { "Final Investment": number; count: number };
//   };
//   data.Date.forEach((date, index) => {
//     const month = date.slice(0, 7); // Get YYYY-MM format
//     if (!aggregatedData[month]) {
//       aggregatedData[month] = {
//         "Final Investment": 0,
//         count: 0,
//       };
//     }
//     aggregatedData[month]["Final Investment"] +=
//       data["Final Investment"][index];
//     aggregatedData[month].count += 1;
//   });

//   const months = Object.keys(aggregatedData);
//   const finalData = {
//     Date: months,
//     "Final Investment": months.map(
//       (month) =>
//         aggregatedData[month]["Final Investment"] / aggregatedData[month].count
//     ),
//   };
//   return finalData;
// };

const Home = () => {
  const currentYear = new Date().getFullYear();
  const getLastMonth = () => {
    const now = new Date();
    const lastMonthIndex = now.getMonth() - 1; // getMonth() returns 0 for Jan, 1 for Feb, etc.
    return months[lastMonthIndex < 0 ? 11 : lastMonthIndex]; // If last month is negative, wrap around to December
  };

  const [monthValue, setMonthValue] = useState(getLastMonth());
  const [yearValue, setYearValue] = useState(currentYear.toString());

  // useEffect(() => {
  //   setMonthValue(months[months.length - 1]);
  // }, [months]);
  const imageData = [
    // {
    //   link:"assets/images/stock-selection/img1.png",
    // },
    {
      link: "assets/images/stock-selection/img2.png",
    },
    {
      link: "assets/images/stock-selection/img3.png",
    },
    {
      link: "assets/images/stock-selection/img4.png",
    },
    {
      link: "assets/images/stock-selection/img5.png",
    },
  ];

  const [showAnimation, setShowAnimation] = useState(false);
  const [portfolioValue, setPortfolioValue] = useState<string>(
    "β-Based Stock Membership Strategy"
  );

  const [UniverseValue, setUniverseValue] = useState<string>(
    "Optimized Performers"
  );
  // const [monthValue, setMonthValue] = useState<string>("Jan");
  // const [yearValue, setYearValue] = useState<string>("2024");

  const [graphData, setGraphData] = useState<GraphData | null>(GraphDataDummy);

  const [plotData, setPlotData] = useState<PlotData | null>(PlotDataDummy);

  const [historicalData, setHistoricalData] =
    useState<HistoricalData>(HistoricalDataDummy);

  const fetchData = async () => {
    // console.log(
    //   // portfolioValue,
    //   // monthValue,
    //   // yearValue,
    //   `https://algoanalytics-fabric-website.s3.ap-south-1.amazonaws.com/Json/analysis-fabric-files/${encodeURI(
    //     portfolioValue
    //   )}/${encodeURI(monthValue)}+${encodeURI(yearValue)}.json`
    // );

    try {
      const response = await fetch(
        (process.env.REACT_APP_STOCK_SELECTION || "") +
          `/Stock-Selection/analysis-fabric-files/${encodeURI(
            portfolioValue
          )}/${encodeURI(monthValue)}+${encodeURI(yearValue)}.json`
      );
      const data = await response.json();
      // console.log(data);
      setGraphData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchPlotData = async () => {
    try {
      const response = await fetch(
        (process.env.REACT_APP_STOCK_SELECTION || "") +
          `/Stock-Selection/backtest_results_daily/β-Based Stock Membership Strategy/plots_data.json`
      );
      const data = await response.json();
      // console.log(data);
      setPlotData(data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchHistoricalData = async () => {
    try {
      const response = await fetch(
        (process.env.REACT_APP_STOCK_SELECTION || "") +
          `/Stock-Selection/backtest_results_daily/β-Based Stock Membership Strategy/table_data.json`
      );
      const data = await response.json();
      console.log(data);
      setHistoricalData(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    fetchPlotData();
    fetchHistoricalData();
  }, [portfolioValue, monthValue, yearValue]);

  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(true);
    }, 50);
  }, []);

  // const currentYear = new Date().getFullYear();

  return (
    <>
      <Navbar />
      <SubNavbar navData={subNavData} />
      <section className="stock-selection-home">
        <h1 className="title">Our Trading Strategy</h1>

        <div className="stock-selection-animation">
          <img
            className="img1"
            src="assets/images/stock-selection/img1.png"
            alt="stock-selection-info"
          />
          {imageData.map((item, i) => (
            <img
              className={`img img-animation img${i + 2} ${
                showAnimation ? "img-animation-start" : ""
              }`}
              src={item.link}
              alt="stock-selection-info"
              key={i}
              style={{ transitionDelay: `${i + 1}s` }}
            />
          ))}
        </div>

        <div className="subtext">
          <h5>
            By following this methodology, we build a portfolio comprising 8
            diverse stocks, confident in the potential for both higher returns
            and risk mitigation.
          </h5>
          <h1>
            Let's Evaluate the Effectiveness of this trading strategy by running
            it against historical data of 10years
          </h1>
        </div>
        <div className="secondary-navbar">
          <div className="secondary-navbar-tab">
            β-Based Stock Membership Strategy
          </div>
          {/* <div className="secondary-navbar-tab">
            NSE Stock Membership Strategy
          </div> */}
        </div>
        <hr className="divider" />
        <div className="line-plot">
          {plotData && (
            <Plot
              data={[
                "NIFTYBEES",
                "Universe 0 (TMI)",
                "Filter 1 Stocks",
                "Filter 2 Stocks",
                "Filter 3 Stocks Max Sharpe 1Y",
              ].map((filterKey) => {
                let legendName = filterKey; // Default legend name is the filter key itself
                if (filterKey === "Universe 0 (TMI)") {
                  legendName = "Total Market Index";
                }
                if (filterKey === "Filter 1 Stocks") {
                  legendName = "Steady Performers";
                }
                if (filterKey === "Filter 2 Stocks") {
                  legendName = "Nifty Beaters";
                }
                if (filterKey === "Filter 3 Stocks Max Sharpe 1Y") {
                  legendName = "Optimized Performers";
                }

                const filterData = plotData[filterKey as keyof PlotData];
                // const aggregatedData = aggregateByMonth(filterData);
                return {
                  x: filterData.Date,
                  y: filterData["Final Investment"],
                  type: "scatter",
                  mode: "lines",
                  name: legendName,
                };
              })}
              layout={{
                title: "Investment Over Time comparison for all stock filters",
                xaxis: { title: "Year" },
                yaxis: { title: "Final Investment" },
                legend: {
                  orientation: "h",
                  y: -0.3,
                },
              }}
              style={{ width: "80%", height: "100%" }}
            />
          )}
        </div>
        <div className="portfolio-section">
          <h1>Portfolio Historical Performance Review</h1>
          <div className="text-image-split">
            <div className="text">
              <h5>
                From the Graph we see that when it comes to constructing
                portfolios, Optimized Performers Stocks yield the most
                favourable outcomes.
              </h5>
              <br />
              <h5>
                If you had invested Rs. 12,000 a decade ago, today, the value of
                portfolio would be worth a Rs. 154,683.0 That's quite the
                return!
              </h5>
              <br />
              <h5>
                Now, let's talk about the runner-up strategy, Nifty Beaters.
                It's all about selecting stocks with a positive Sharpe ratio
                over the past five years and ones that have outperformed the
                Nifty ETF in the last year.
              </h5>
              <br />
              <h5>
                However, despite these Optimized Performers and 2 portfolios
                come out on top-they have a higher Maximum Drawdown compared to
                the Nifty Bees ETF.
              </h5>
              <br />
            </div>
            {/* <div className="img">
              <img
                src="assets/images/stock-selection/portfolio-table.png"
                alt="portfolio-section"
              />
            </div> */}
            {/* Historical Table */}
            <div className="historical-performance-table">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Parameter</th>
                    <th>NIFTYBEES.NS</th>
                    <th>Total Market Index</th>
                    <th>Steady Performers</th>
                    <th>Nifty Beaters</th>
                    <th>Optimised Performers</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Generate Rows */}
                  {Object.keys(
                    historicalData[Object.keys(historicalData)[0]]
                  ).map((parameterKey, rowIndex) => (
                    <tr key={rowIndex}>
                      <td>{parameterKey}</td>
                      {orderedKeys.map((key, colIndex) => (
                        <td key={colIndex} style={{ color: colors[colIndex] }}>
                          {historicalData[key]?.[parameterKey]?.toFixed(2)}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="analysis">
          <div className="title">
            Explore Historical Performance on Your Terms
          </div>
          <div className="analysis-filters">
            {/* Portfolio Filter NSE based or beta based statergy */}
            <FilterSelect
              options={[
                "β-Based Stock Membership Strategy",
                // "NSE Stock Membership Strategy",
              ]}
              value={portfolioValue}
              onChange={setPortfolioValue}
              placeholder="Select Portfolio Strategy"
            />
            {/* Universe Filter */}
            <FilterSelect
              options={[
                "Steady Performers",
                "Nifty Beaters",
                "Optimized Performers",
              ]}
              value={UniverseValue}
              onChange={setUniverseValue}
              placeholder="Select Universe"
            />

            {/* <div className="month-year-div"> */}
            {/* Month filter */}
            {/* <FilterSelect
              options={[
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
              ]}
              value={monthValue}
              onChange={setMonthValue}
              placeholder="Select Month"
              disabledOptions={getFutureMonths(currentYear, yearValue)}
            /> */}
            {/* Year filter */}
            {/* <FilterSelect
              options={[
                "2024",
                "2023",
                "2022",
                "2021",
                "2020",
                "2019",
                "2018",
                "2017",
                "2016",
                "2015",
                "2014",
              ]}
              value={yearValue}
              onChange={setYearValue}
              placeholder="Select Year"
            /> */}
            {/* {console.log(monthValue + yearValue)} */}
            <FilterSelect
              options={months}
              value={monthValue}
              onChange={setMonthValue}
              placeholder="Select Month"
              disabledOptions={getFutureMonths(currentYear, yearValue)}
            />

            {/* Year filter */}
            <FilterSelect
              options={years}
              value={yearValue}
              onChange={setYearValue}
              placeholder="Select Year"
            />
            {/* </div> */}
          </div>
          </div>
          <div className="heatmap">
            <div className="title">
              Months when {portfolioValue} outperformed NIFTYBEES.NS
            </div>
            <div
              style={{
                width: "70%",
                margin: "0 auto",
              }}
            >
              <HeatMap portfolioValue={portfolioValue} />
            </div>
          </div>
          <div className="line-graph">
            <div className="title">
              {monthValue} {yearValue} Stocks along with their Normalized Price
              Over Time
            </div>
            {/* Line Graph */}
            <div className="normalised-graph">
              <NormalizedLineGraph
                graphData={graphData}
                universeValue={UniverseValue}
              />
            </div>
          </div>
          <div className="backtesting">
            <div className="title">
              Forward Testing Performance {monthValue} {yearValue} stocks
            </div>
            <div className="table-graph-forward-testing">
              <div className="data-table-forward-testing">
                <BacktestingTable
                  graphData={graphData}
                  universeValue={UniverseValue}
                />
              </div>
              <div className="graph-forward-testing">
                <BacktestingGraph
                  graphData={graphData}
                  universeValue={UniverseValue}
                />
              </div>
            </div>
          </div>
        {/* </div> */}
        <MiniFooter />
      </section>
    </>
  );
};

export default Home;
