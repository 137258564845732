import { createContext } from "react";

export type UserInfo = {
  email: string;
  username: string;
  // scopes: string[];//scope array
};
type setUserInfo = (userInfo: UserInfo | null) => void;

export const AuthContext = createContext<{
  userInfo: UserInfo | null;
  setUserInfo: setUserInfo;
}>({ userInfo: null, setUserInfo: () => {} });
